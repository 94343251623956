.tableIn {
  z-index: 9999;
  position: relative;
}
.dd-none {
  z-index: 0;
  opacity: 0;
}
.dd-block {
  z-index: 9999999;
  opacity: 1;
}

#loader-wrapper {
  -webkit-transition: all 0.4s ease-in;
  -moz-transition: all 0.4s ease-in;
  -o-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  background-color: rgba(255, 255, 255, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #4981a7;
  -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #e74c3c;
  -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #f9c922;
  -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}
/* div, *{
border: solid 1px #1a1a1a;
} */

.loaderOne {
  color: #118fe4;
  margin: 100px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(2, 140, 253, 0.2),
      2.5em 0em 0 0em rgba(2, 140, 253, 0.2),
      1.75em 1.75em 0 0em rgba(2, 140, 253, 0.2),
      0em 2.5em 0 0em rgba(2, 140, 253, 0.2),
      -1.8em 1.8em 0 0em rgba(2, 140, 253, 0.2),
      -2.6em 0em 0 0em rgba(2, 140, 253, 0.5),
      -1.8em -1.8em 0 0em rgba(2, 140, 253, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(2, 140, 253, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(2, 140, 253, 0.2),
      1.75em 1.75em 0 0em rgba(2, 140, 253, 0.2),
      0em 2.5em 0 0em rgba(2, 140, 253, 0.2),
      -1.8em 1.8em 0 0em rgba(2, 140, 253, 0.2),
      -2.6em 0em 0 0em rgba(2, 140, 253, 0.2),
      -1.8em -1.8em 0 0em rgba(2, 140, 253, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(2, 140, 253, 0.5),
      1.8em -1.8em 0 0em rgba(2, 140, 253, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(2, 140, 253, 0.2),
      0em 2.5em 0 0em rgba(2, 140, 253, 0.2),
      -1.8em 1.8em 0 0em rgba(2, 140, 253, 0.2),
      -2.6em 0em 0 0em rgba(2, 140, 253, 0.2),
      -1.8em -1.8em 0 0em rgba(2, 140, 253, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(2, 140, 253, 0.2),
      1.8em -1.8em 0 0em rgba(2, 140, 253, 0.5),
      2.5em 0em 0 0em rgba(2, 140, 253, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(2, 140, 253, 0.2),
      -1.8em 1.8em 0 0em rgba(2, 140, 253, 0.2),
      -2.6em 0em 0 0em rgba(2, 140, 253, 0.2),
      -1.8em -1.8em 0 0em rgba(2, 140, 253, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(2, 140, 253, 0.2),
      1.8em -1.8em 0 0em rgba(2, 140, 253, 0.2),
      2.5em 0em 0 0em rgba(2, 140, 253, 0.5),
      1.75em 1.75em 0 0em rgba(2, 140, 253, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(2, 140, 253, 0.2),
      -2.6em 0em 0 0em rgba(2, 140, 253, 0.2),
      -1.8em -1.8em 0 0em rgba(2, 140, 253, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(2, 140, 253, 0.2),
      1.8em -1.8em 0 0em rgba(2, 140, 253, 0.2),
      2.5em 0em 0 0em rgba(2, 140, 253, 0.2),
      1.75em 1.75em 0 0em rgba(2, 140, 253, 0.5),
      0em 2.5em 0 0em rgba(2, 140, 253, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(2, 140, 253, 0.2),
      -1.8em -1.8em 0 0em rgba(2, 140, 253, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(2, 140, 253, 0.2),
      1.8em -1.8em 0 0em rgba(2, 140, 253, 0.2),
      2.5em 0em 0 0em rgba(2, 140, 253, 0.2),
      1.75em 1.75em 0 0em rgba(2, 140, 253, 0.2),
      0em 2.5em 0 0em rgba(2, 140, 253, 0.5),
      -1.8em 1.8em 0 0em rgba(2, 140, 253, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(2, 140, 253, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(2, 140, 253, 0.2),
      1.8em -1.8em 0 0em rgba(2, 140, 253, 0.2),
      2.5em 0em 0 0em rgba(2, 140, 253, 0.2),
      1.75em 1.75em 0 0em rgba(2, 140, 253, 0.2),
      0em 2.5em 0 0em rgba(2, 140, 253, 0.2),
      -1.8em 1.8em 0 0em rgba(2, 140, 253, 0.5),
      -2.6em 0em 0 0em rgba(2, 140, 253, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(2, 140, 253, 0.2),
      2.5em 0em 0 0em rgba(2, 140, 253, 0.2),
      1.75em 1.75em 0 0em rgba(2, 140, 253, 0.2),
      0em 2.5em 0 0em rgba(2, 140, 253, 0.2),
      -1.8em 1.8em 0 0em rgba(2, 140, 253, 0.2),
      -2.6em 0em 0 0em rgba(2, 140, 253, 0.5),
      -1.8em -1.8em 0 0em rgba(2, 140, 253, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(2, 140, 253, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(2, 140, 253, 0.2),
      1.75em 1.75em 0 0em rgba(2, 140, 253, 0.2),
      0em 2.5em 0 0em rgba(2, 140, 253, 0.2),
      -1.8em 1.8em 0 0em rgba(2, 140, 253, 0.2),
      -2.6em 0em 0 0em rgba(2, 140, 253, 0.2),
      -1.8em -1.8em 0 0em rgba(2, 140, 253, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(2, 140, 253, 0.5),
      1.8em -1.8em 0 0em rgba(2, 140, 253, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(2, 140, 253, 0.2),
      0em 2.5em 0 0em rgba(2, 140, 253, 0.2),
      -1.8em 1.8em 0 0em rgba(2, 140, 253, 0.2),
      -2.6em 0em 0 0em rgba(2, 140, 253, 0.2),
      -1.8em -1.8em 0 0em rgba(2, 140, 253, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(2, 140, 253, 0.2),
      1.8em -1.8em 0 0em rgba(2, 140, 253, 0.5),
      2.5em 0em 0 0em rgba(2, 140, 253, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(2, 140, 253, 0.2),
      -1.8em 1.8em 0 0em rgba(2, 140, 253, 0.2),
      -2.6em 0em 0 0em rgba(2, 140, 253, 0.2),
      -1.8em -1.8em 0 0em rgba(2, 140, 253, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(2, 140, 253, 0.2),
      1.8em -1.8em 0 0em rgba(2, 140, 253, 0.2),
      2.5em 0em 0 0em rgba(2, 140, 253, 0.5),
      1.75em 1.75em 0 0em rgba(2, 140, 253, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(2, 140, 253, 0.2),
      -2.6em 0em 0 0em rgba(2, 140, 253, 0.2),
      -1.8em -1.8em 0 0em rgba(2, 140, 253, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(2, 140, 253, 0.2),
      1.8em -1.8em 0 0em rgba(2, 140, 253, 0.2),
      2.5em 0em 0 0em rgba(2, 140, 253, 0.2),
      1.75em 1.75em 0 0em rgba(2, 140, 253, 0.5),
      0em 2.5em 0 0em rgba(2, 140, 253, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(2, 140, 253, 0.2),
      -1.8em -1.8em 0 0em rgba(2, 140, 253, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(2, 140, 253, 0.2),
      1.8em -1.8em 0 0em rgba(2, 140, 253, 0.2),
      2.5em 0em 0 0em rgba(2, 140, 253, 0.2),
      1.75em 1.75em 0 0em rgba(2, 140, 253, 0.2),
      0em 2.5em 0 0em rgba(2, 140, 253, 0.5),
      -1.8em 1.8em 0 0em rgba(2, 140, 253, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(2, 140, 253, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(2, 140, 253, 0.2),
      1.8em -1.8em 0 0em rgba(2, 140, 253, 0.2),
      2.5em 0em 0 0em rgba(2, 140, 253, 0.2),
      1.75em 1.75em 0 0em rgba(2, 140, 253, 0.2),
      0em 2.5em 0 0em rgba(2, 140, 253, 0.2),
      -1.8em 1.8em 0 0em rgba(2, 140, 253, 0.5),
      -2.6em 0em 0 0em rgba(2, 140, 253, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

.card {
  -webkit-box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.8) !important;
  border: 0;
}
.card-header {
  background-color: rgba(0, 0, 0, 0);
}
.card-footer {
  background-color: rgba(0, 0, 0, 0);
}

/**Tabla css bootstrap**/
.table-crud td {
  border-top: 1px solid #8f8f8f;
}
.table-crud th {
  padding: 11px !important;
}
.table td,
.table th {
  border: 1px solid rgba(0, 0, 0, 0.125);
  color: rgba(255, 255, 255, 0.8);
}
.table {
  transition: all 0.5;
}

.table tr:hover {
  background-color: rgba(94, 255, 148, 0.125);
}

.fa-pencil:hover,
.fa-trash:hover {
  background-attachment: #1a1a1a;
  color: #e5ffdf;
}

/**Pie de pagina crud**/
.page-link {
  color: #1a1a1a;
  font-weight: bold;
}

.crudFondo {
  background-color: rgba(255, 255, 255, 0.06);
  padding: 10px 0px;
  margin: auto;
  width: 75%;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #c9c9c9 !important;
}
.card-crud {
  width: 75%;
  margin: auto;
  color: rgba(255, 255, 255, 0.8);
}
@media (max-width: 1024px) {
  .card-crud {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .card-crud {
    width: 98%;
    margin: auto;
  }
}

.pagination div,
.pagination select {
  color: rgba(255, 255, 255, 0.6) !important;
  background-color: rgba(255, 255, 255, 0.06) !important;
}

.page-link:hover {
  color: rgba(255, 255, 255, 0.2) !important;
  background-color: rgba(153, 0, 255, 0.06);
}

.page-link option {
  color: rgba(39, 39, 39, 0.8) !important;
  background-color: rgba(178, 248, 86, 0.6) !important;
}

.pagination select:focus {
  color: rgba(255, 255, 255, 0.6) !important;
  background-color: rgba(255, 255, 255, 0.06) !important;
}

.form-filter {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: rgba(255, 255, 255, 0.9) !important;
}

.modal-change {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: rgba(255, 255, 255, 0.8);
}

.btn-crud {
  background-color: rgba(255, 255, 255, 0.2) !important;
  color: #fff !important;
}

.no-edit{
  /* background-color: #118fe4; */
  display: none !important;
}

.add-img{
  display: none !important;
}
.add-img-block{
  display: block !important;
}
