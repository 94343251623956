
@import 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.4.0/css/font-awesome.css';



@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');

@import 'https://david-perez-portafolio.neonbo.com/json/animacion.css';

body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-size: 14px !important;
    line-height: 1.42857143 !important;
    color: rgba(255, 255, 255, 0.8);
    transition: all 0.4s;
    background-image: url('https://david-perez-portafolio.neonbo.com/img/fondotren-blur.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.container_1024{
    max-width: 1024px;
    width: 98%;
    margin: auto;
}
p {
    font-family: 'Roboto', sans-serif !important;
    font-size: 1.1em !important;
    line-height: 1.45em !important;
    text-align: justify;
    text-justify: inter-word;
    
}

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none !important;
    transition: all 0.4s;
}
a{
    color: #1a1a1a !important;
}

.navbar {
    border: none;
    border-radius: 0;
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

i, span {
    display: inline-block;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.wrapper {
    display: flex;
    align-item: stretch;
    perspective: 1024px;

}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    color: rgba(255, 255, 255, 0.1);
    transition: all 0.8s ease;
    background-color: rgba(255, 255, 255, 0.06);
}

#sidebar.active {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
    
}

#sidebar.active .sidebar-header img, #sidebar.active .CTAs {
    display: none;
}

#sidebar.active .sidebar-header strong {
    display: block;
}

#sidebar.active ul li a {
    font-size: 0.85em !important;
    
}

#sidebar.active a[aria-expanded="false"]::before, #sidebar.active a[aria-expanded="true"]::before {
    top: auto;
    bottom: 0px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
}

#sidebar.active .lista-sidebar{
   margin: 9px;
}

#sidebar .sidebar-header {
    padding: 20px;
    /* background: rgba(255, 255, 255, 0.6); */
    overflow: hidden;
    color: rgba(255, 255, 255, 0.8);
}

#sidebar .sidebar-header strong {
    display: none;
    font-size: 1.8em !important;
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #47748b;
}


#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em !important;
    transition: all 0.5s;
    color: rgba(255, 255, 255, 0.8) !important;
}


#sidebar ul li a:hover {
    color: #ffffff;
    background: linear-gradient( 0deg, rgb(255 245 119 / 68%) 0%, rgb(216 202 38) 50%, rgb(249 251 144 / 35%) 100%);
}
#sidebar ul li a i {
    margin-right: 10px;
    color: rgba(255, 255, 255, 0.8);
}

#sidebar ul li.active > a, a[aria-expanded="true"] {
    color: #1a1a1a;
    /* background: rgba(255, 255, 255, 0.6); */
}


a[data-toggle="collapse"] {
    position: relative;
}

a[aria-expanded="false"]::before, a[aria-expanded="true"]::before {
    content: "\f107";
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 1.2em !important;
    
}
a[aria-expanded="true"]::before {
    content: "\f107";
}


ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
 
  
}

a.download {
    background: #fff;
    color: #1a1a1a;
}

a.article, a.article:hover {
    background: rgba(255, 255, 255, 0.6) !important;
    color: #1a1a1a !important;
}

.lista-sidebar{
    margin: 2px 0px 0px 15px;
    
}

.flex-icon-sidebar{
    display: flex;
	justify-content: flex-start;
	align-items: center ;
}

#sidebar.active .flex-icon-sidebar{
    flex-direction: column;
	align-items: center;
};

#sidebar.active .lista-sidebar{
    margin: 4px 0px 0px 0px !important;
}

#sidebar ul li ul li a{
    display: block;
}
#sidebar ul li ul li{
    padding-left: 40px;
}

#sidebar.active ul li ul li{
    padding-left: 0px;
}


/*-------------------
Sidebar colapse
----------------*/
#sidebarCollapse {
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255,255, 0.2);
}

#sidebarCollapse span {
    width: 80%;
    height: 2px;
    margin: 0 auto;
    display: block;
    background: rgb(107, 228, 70);
    transition: all 0.4s cubic-bezier(0.810, -0.330, 0.345, 1.375);
    transition-delay: 0.1s;
    color: #000;
}

#sidebarCollapse span:first-of-type {
    transform: rotate(45deg) translate(2px, 2px);
}
#sidebarCollapse span:nth-of-type(2) {
    opacity: 0;
}
#sidebarCollapse span:last-of-type {
    transform: rotate(-45deg) translate(1px, -1px);
}
#sidebarCollapse.active span {
    transform: none;
    opacity: 1;
    margin: 5px auto;
}
/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content {
    width: 100%;
    padding: 10px;
    min-height: 100vh;
    margin-left: 8px;
}

/* ---------------------------------------------------
    M STYLE
----------------------------------------------------- */
.dpos{
    position: absolute;
    display: block;
    
 }

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (max-width: 768px) {
    body{
        background-size: 100% 100%;
    }
    /* #sidebar {
        min-width: 80px;
         max-width: 80px;
        text-align: center;
        margin-left: -80px !important ;
    }   */
    a[aria-expanded="false"]::before, a[aria-expanded="true"]::before {
        right: 20px;
        top: 20px;
    }
    #sidebar.active {
        margin-left: -87px !important;
    }
    #sidebar .sidebar-header strong {
        display: block;
    }
    #sidebar ul li a i {
        font-size: 1.3em !important;
    }
    #sidebar {
        margin-left: 0;
    }
    #sidebarCollapse span {
        /* display: none; */
    }
}


/* Sidebar en select option colapse CRUD*/
.colapsep-pre{
    overflow: hidden;
    transition: all 0.4s;
    height: 0px;
}
.collapse-d-crud{
    height: 0;
}
.no-collapse-d-crud{
    height: 90px;
}

/* Sidebar en select option colapse ENTERTAMEINT*/
.collapse-d-entretenimiento{
    height: 0;
}
.no-collapse-d-entretenimiento{
    
    height: 120px;
}

/*Sidebar flutter*/
.collapse-d-flutter{
    height: 0;
}
.no-collapse-d-flutter{
    height: 120px;
}


.img-circle{
    width: 150px;
    height: 150px;
    border-radius: 50%;
}
.blanck-card{
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: .25rem !important;
}

/*Componente de container unico semantica/container.js*/
.over-flawer{
    overflow: hidden;
}

/*Para el tamaño de todos los iframes*/

@media (max-width: 400px){
    #content {
        margin-left: 8px;
        width: fit-content;   
    }
}




