.iframe-height{
    margin: auto;
    max-width: 1024px;
    width: 100%;
    height: 700px

}
.iframe-hei{
    height: 700px
}

@media (max-width: 400px){

    .iframe-height{
        margin: auto;
        width: 400px ;
        height: 500px;
    
    }
    .iframe-hei{
        height: 500px
    }
}

@media (max-width: 350px){
    .iframe-height{
        margin: auto;
        width: 320px ;
        height: 500px;
    }
    .iframe-hei{
        height: 500px
    }
}
@media (max-width: 768px){

    .iframe-height{
        margin: auto;
        width: 600px ;
        height: 600px;
    }
    .iframe-hei{
        height: 600px
    }
}