/**/

.experience{
    position: relative;
    width: 20px;
    height: auto;
    transition: all 0.3s ease-out ;
    opacity: 0.5;
    display: none;
}

/* Flechas hacia abajo recorrre left */
.anime1{
    left: 15%;
    opacity: 0.9;
    display: block;
}
.anime2{
    left: 51%;
    opacity: 0.9;
    display: block;
}
.anime3{
    left: 85%;
    opacity: 0.9;
    display: block;
}


.slider-cv{
    /* height: 0; */
    /* display: none; */
    transition: all 0.3s ease-out ;
     opacity: 0.5; 
}

.slider-cv-anima{
    /* height: 300px; */
    /* display: block;*/
     opacity: 1; 
}
.slider-cv-anima-no{
   /*  height: 0px; */
     opacity: 0.5;
}

.slideOutUp {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
}

.card-cv {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  /* border: 1px solid rgba(255, 255, 255, 0.925);
  border-radius: 1.15rem; */
  box-shadow: -8px 5px 4px  rgba(51, 51, 51, 0.2);
  transition: all .5s ease-out;
  margin-top: 10px;
}
/* .card-cv-1{
  border: 2px solid rgba(117, 137, 220, 0.5);
}
.card-cv-2{
  border: 2px solid rgba(167, 224, 128, 0.5);
}
.card-cv-3{
  border: 2px solid rgba(180, 77, 73, 0.5);
} */
.exp-1{
  color: #ffffff;
  background: rgb(62,83,173);
  background: linear-gradient(0deg
, rgb(188 190 191) 0%, rgba(95,120,225,0.8500175070028011) 50%, rgba(137,160,255,0.5959558823529411) 100%);

}
.exp-1:hover{
  
  background: linear-gradient(45deg, rgb(188 190 191) 0%, rgba(95,120,225,0.8500175070028011) 50%, rgba(137,160,255,0.5959558823529411) 100%);
  box-shadow: -1px 14px 4px  rgba(51, 51, 51, 0.2);
}



.exp-2{
  color: #ffffff;
  background: linear-gradient( 0deg
     , rgb(180 218 183) 0%, rgb(109 202 124) 50%, rgb(121 202 133 / 35%) 100%);
}
.exp-2:hover{
 
  background: linear-gradient( 15deg
     , rgb(180 218 183) 0%, rgb(109 202 124) 50%, rgb(121 202 133 / 35%) 100%);
     box-shadow: -1px 14px 4px  rgba(51, 51, 51, 0.2);
}

.exp-3{
  color: #ffffff;
  background: linear-gradient( 0deg
     , rgb(236 158 158 / 69%) 0%, rgb(230 60 60) 50%, rgb(255 137 137 / 60%) 100%);

}
.exp-3:hover{
  
  background: linear-gradient( 45deg, rgb(236 158 158 / 69%) 0%, rgb(230 60 60) 50%, rgb(255 137 137 / 60%) 100%);
  box-shadow: -1px 14px 4px  rgba(51, 51, 51, 0.2);

}


.dummy__item {
	margin: 1em 1.25em;
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
	-webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
	transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
}

.dummy__item {
	-webkit-transform: translate3d(-100%, 0, 0) translate3d(-2em, 0, 0) scale3d(0.5, 1, 1);
	transform: translate3d(-100%, 0, 0) translate3d(-2em, 0, 0) scale3d(0.5, 1, 1);
	-webkit-transform-origin: 100% 50%;
	transform-origin: 100% 50%;
}

.device--alt .dummy__item {
	-webkit-transform: translate3d(0, 260px, 0) scale3d(1, 0.2, 1);
	transform: translate3d(0, 260px, 0) scale3d(1, 0.2, 1);
}

.dummy--active .dummy__item {
	-webkit-transition-timing-function: cubic-bezier(0.56, 1.19, 0.2, 1.05);
	transition-timing-function: cubic-bezier(0.56, 1.19, 0.2, 1.05);
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}
.dummy__item:nth-child(6),
.dummy--active .dummy__item:first-child {
	-webkit-transition-delay: 0.04s;
	transition-delay: 0.04s;
}

.dummy__item:nth-child(5),
.dummy--active .dummy__item:first-child {
	-webkit-transition-delay: 0.05s;
	transition-delay: 0.05s;
}

.dummy__item:nth-child(4),
.dummy--active .dummy__item:first-child {
	-webkit-transition-delay: 0.10s;
	transition-delay: 0.10s;
}

.dummy__item:nth-child(3),
.dummy--active .dummy__item:nth-child(2) {
	-webkit-transition-delay: 0.15s;
	transition-delay: 0.15s;
}

.dummy__item:nth-child(2),
.dummy--active .dummy__item:nth-child(3) {
	-webkit-transition-delay: 0.18s;
	transition-delay: 0.18s;
}

.dummy__item:first-child,
.dummy--active .dummy__item:nth-child(4) {
	-webkit-transition-delay: 0.25s;
	transition-delay: 0.25s;
}


.btn-cv{
    padding:.4rem;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: rgba(255, 255, 255, 0.9);
    color: #000;
    
  }


.p-cv{
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.4);
  background-clip: border-box;
  
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,c0c2c2+100&1+0,0.55+100 */
  background: -moz-linear-gradient(left,  rgba(255,255,255,0.6) 0%, rgba(192,194,194,0.05) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  rgba(255,255,255,0.6) 0%,rgba(192,194,194,0.05) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  rgba(255,255,255,0.6) 0%,rgba(192,194,194,0.05) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#a8c0c2c2',GradientType=1 ); /* IE6-9 */
  /* box-shadow: -8px 5px 4px  rgba(51, 51, 51, 0.1); */
  margin-left: 10px;
  color: rgba(14, 14, 14, 0.9)

}


.white-cv{
  color: rgb(255, 255, 255);
}

.card-cv-content{
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: -8px 5px 4px  rgba(51, 51, 51, 0.1);
  color: rgba(34, 34, 34, 1);
}



/***---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------css nuevo carrucel***/

.cont-d{
  width: 300px;
}
.carousel-caption{
  /* background-color: rgba(0, 0, 0, 0.8); */
} 
/* div{
  border: solid 1px #1a1a1a;
} */

.carousel-caption {
  right: 0 !important; 
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important; 
  height: 100%;
  

}

.img-carrucel{
  opacity: 0.75;
}

.w-img{
  width: 150px;
  height: auto;
}


.iconos-perfil{
  text-align: center;
  height:auto;
  width: 100%; 
}
.circ-neon{
  border-radius: 7px;
  width: 150px;
  height: 150px;
}
.dos-circ{
  width: fit-content;
  border-radius: 7px;
}

 .to{
  border:2px solid #308BC4;
  background: rgba(0, 0, 0, 0.0);
  box-shadow: 0px 1px 30px #308BC4;
  -moz-box-shadow:0px 1px 30px #308BC4; /* FF*/
  -webkit-box-shadow:0px 1px 30px #308BC4;
  box-shadow:0px 1px 30px #308BC4; /*WC3*/
}
.oe{
  border: 2px solid #FFF;
  width: 15;
}
.tit{
  font-family: 'Montez', cursive;
  color: #FFF;
  background: rgba(0, 0, 0, 0.6);
  text-shadow:1px 1px 15px #308BC4;
  border: 2px solid #308BC4;
}
.tit{
  -webkit-animation: mymove 5s infinite; 
  animation: mymove 5s infinite;
}

@keyframes mymove {
  100%   {

    border:2px solid #308BC4;

    box-shadow:  0px 1px 30px #308BC4;
    -moz-box-shadow: 0px 1px 30px #308BC4;
    -webkit-box-shadow: 0px 1px 30px #308BC4;

}
75%  {

    border:2px solid #c9c92e;

    box-shadow:  0px 1px 30px #c9c92e;
    -moz-box-shadow: 0px 1px 30px #c9c92e;
    -webkit-box-shadow: 0px 1px 30px #c9c92e;

}
25%  {

    border:2px solid #37c130;
    box-shadow:  0px 1px 30px #37c130;
    -moz-box-shadow: 0px 1px 30px #37c130;
    -webkit-box-shadow: 0px 1px 30px #37c130;

}
0% {
    border:2px solid #982dc6;
    box-shadow:  0px 1px 30px #982dc6;
    -moz-box-shadow: 0px 1px 30px #982dc6;
    -webkit-box-shadow: 0px 1px 30px #982dc6;

}

}

.to{
   
    
    
   -webkit-animation: mymoveDos 5s infinite; 
    animation: mymoveDos 5s infinite;
}

@keyframes mymoveDos {
 100%   {

    border:2px solid #308BC4;

    box-shadow:inset  0px 1px 30px #308BC4;
    -moz-box-shadow:inset 0px 1px 30px #308BC4;
    -webkit-box-shadow:inset 0px 1px 30px #308BC4;

}
75%  {

    border:2px solid #c9c92e;

    box-shadow:inset  0px 1px 30px #c9c92e;
    -moz-box-shadow:inset 0px 1px 30px #c9c92e;
    -webkit-box-shadow:inset 0px 1px 30px #c9c92e;

}
25%  {

    border:2px solid #37c130;
    box-shadow:inset  0px 1px 30px #37c130;
    -moz-box-shadow:inset 0px 1px 30px #37c130;
    -webkit-box-shadow:inset 0px 1px 30px #37c130;

}
0% {
    border:2px solid #982dc6;
    box-shadow:inset  0px 1px 30px #982dc6;
    -moz-box-shadow:inset 0px 1px 30px #982dc6;
    -webkit-box-shadow:inset 0px 1px 30px #982dc6;

}
 
}


/*-------------- 1 --------------*/

.to1{
  
  border:2px solid #37c130;
  background: rgba(0, 0, 0, 0.0);
  box-shadow: 0px 1px 30px #37c130;
  -moz-box-shadow:0px 1px 30px #37c130; /* FF*/
  -webkit-box-shadow:0px 1px 30px #37c130;
  box-shadow:0px 1px 30px #37c130; /*WC3*/

 
  
}
.oe1{
  border: 2px solid #FFF;
  width: 15;
 
  
}
.tit1{
  
 
  
  font-family: 'Montez', cursive;
  color: #FFF;
  background: rgba(0, 0, 0, 0.6);
  text-shadow:1px 1px 15px #37c130;
  border:2px solid #37c130;
 
  

}
.tit1{
   
   -webkit-animation: mymove1 5s infinite; 
    animation: mymove1 5s infinite;
}

@keyframes mymove1 {
  0%  {

    border:2px solid #37c130;
    box-shadow:  0px 1px 30px #37c130;
    -moz-box-shadow: 0px 1px 30px #37c130;
    -webkit-box-shadow: 0px 1px 30px #37c130;

}
25%  {

  border:2px solid #c9c92e;

  box-shadow:  0px 1px 30px #c9c92e;
  -moz-box-shadow: 0px 1px 30px #c9c92e;
  -webkit-box-shadow: 0px 1px 30px #c9c92e;

}
    75%   {

        border:2px solid #308BC4;
 
        box-shadow:  0px 1px 30px #308BC4;
        -moz-box-shadow: 0px 1px 30px #308BC4;
        -webkit-box-shadow: 0px 1px 30px #308BC4;

    }
100% {
    border:2px solid #982dc6;
    box-shadow:  0px 1px 30px #982dc6;
    -moz-box-shadow: 0px 1px 30px #982dc6;
    -webkit-box-shadow: 0px 1px 30px #982dc6;

}
 75%   {

    border:2px solid #308BC4;

    box-shadow:  0px 1px 30px #308BC4;
    -moz-box-shadow: 0px 1px 30px #308BC4;
    -webkit-box-shadow: 0px 1px 30px #308BC4;

}
50%  {

    border:2px solid #c9c92e;

    box-shadow:  0px 1px 30px #c9c92e;
    -moz-box-shadow: 0px 1px 30px #c9c92e;
    -webkit-box-shadow: 0px 1px 30px #c9c92e;

}
25%  {

    border:2px solid #37c130;
    box-shadow:  0px 1px 30px #37c130;
    -moz-box-shadow: 0px 1px 30px #37c130;
    -webkit-box-shadow: 0px 1px 30px #37c130;

}
0% {
    border:2px solid #982dc6;
    box-shadow:  0px 1px 30px #982dc6;
    -moz-box-shadow: 0px 1px 30px #982dc6;
    -webkit-box-shadow: 0px 1px 30px #982dc6;

}
}

.to1{
   
    
    
   -webkit-animation: mymoveDos1 5s infinite; 
    animation: mymoveDos1 5s infinite;
}

@keyframes mymoveDos1 {
  0%  {

    border:2px solid #37c130;
    box-shadow:inset  0px 1px 30px #37c130;
    -moz-box-shadow:inset 0px 1px 30px #37c130;
    -webkit-box-shadow:inset 0px 1px 30px #37c130;

}
25%  {

  border:2px solid #c9c92e;

  box-shadow:inset  0px 1px 30px #c9c92e;
  -moz-box-shadow:inset 0px 1px 30px #c9c92e;
  -webkit-box-shadow:inset 0px 1px 30px #c9c92e;

}
    75%   {

        border:2px solid #308BC4;
 
        box-shadow:inset  0px 1px 30px #308BC4;
        -moz-box-shadow:inset 0px 1px 30px #308BC4;
        -webkit-box-shadow:inset 0px 1px 30px #308BC4;

    }
100% {
    border:2px solid #982dc6;
    box-shadow: inset  0px 1px 30px #982dc6;
    -moz-box-shadow: inset 0px 1px 30px #982dc6;
    -webkit-box-shadow: inset 0px 1px 30px #982dc6;

}
 75%   {

    border:2px solid #308BC4;

    box-shadow: inset  0px 1px 30px #308BC4;
    -moz-box-shadow: inset 0px 1px 30px #308BC4;
    -webkit-box-shadow: inset 0px 1px 30px #308BC4;

}
50%  {

    border:2px solid #c9c92e;

    box-shadow: inset  0px 1px 30px #c9c92e;
    -moz-box-shadow: inset 0px 1px 30px #c9c92e;
    -webkit-box-shadow: inset 0px 1px 30px #c9c92e;

}
25%  {

    border:2px solid #37c130;
    box-shadow: inset  0px 1px 30px #37c130;
    -moz-box-shadow: inset 0px 1px 30px #37c130;
    -webkit-box-shadow: inset 0px 1px 30px #37c130;

}
0% {
    border:2px solid #982dc6;
    box-shadow: inset  0px 1px 30px #982dc6;
    -moz-box-shadow: inset 0px 1px 30px #982dc6;
    -webkit-box-shadow: inset 0px 1px 30px #982dc6;

}
}



/*-------------- 2 --------------*/

.to2{
  
  border:2px solid #c13060;
  background: rgba(0, 0, 0, 0.0);
  box-shadow: 0px 1px 30px #c13060;
  -moz-box-shadow:0px 1px 30px #c13060; /* FF*/
  -webkit-box-shadow:0px 1px 30px #c13060;
  box-shadow:0px 1px 30px #c13060; /*WC3*/

 
  
}
.oe2{
  border: 2px solid #FFF;
  width: 15;
 
  
}
.tit2{
  
 
  
  font-family: 'Montez', cursive;
  color: #FFF;
  background: rgba(0, 0, 0, 0.6);
  text-shadow:1px 1px 15px #c13060;
  border:2px solid #c13060;
 
  

}
.tit2{
   
   -webkit-animation: mymove2 5s infinite; 
    animation: mymove2 5s infinite;
}

/* Chrome, Safari, Opera */ 


/* Standard syntax */
@keyframes mymove2 {
  0%  {

    border:2px solid #c13060;
    box-shadow:  0px 1px 30px #c13060;
    -moz-box-shadow: 0px 1px 30px #c13060;
    -webkit-box-shadow: 0px 1px 30px #c13060;

}
25%  {

  border:2px solid #5ac92e;

  box-shadow:  0px 1px 30px #5ac92e;
  -moz-box-shadow: 0px 1px 30px #5ac92e;
  -webkit-box-shadow: 0px 1px 30px #5ac92e;

}
    75%   {

        border:2px solid #308BC4;
 
        box-shadow:  0px 1px 30px #308BC4;
        -moz-box-shadow: 0px 1px 30px #308BC4;
        -webkit-box-shadow: 0px 1px 30px #308BC4;

    }
100% {
    border:2px solid #4de7c6;
    box-shadow:  0px 1px 30px #4de7c6;
    -moz-box-shadow: 0px 1px 30px #4de7c6;
    -webkit-box-shadow: 0px 1px 30px #4de7c6;

}

}

.to2{
   
    
    
   -webkit-animation: mymoveDos2 5s infinite; 
    animation: mymoveDos2 5s infinite;
}

@keyframes mymoveDos2 {
  0%  {

    border:2px solid #c13060;
    box-shadow:inset  0px 1px 30px #c13060;
    -moz-box-shadow:inset 0px 1px 30px #c13060;
    -webkit-box-shadow:inset 0px 1px 30px #c13060;

}
25%  {

  border:2px solid #5ac92e;

  box-shadow:inset  0px 1px 30px #5ac92e;
  -moz-box-shadow:inset 0px 1px 30px #5ac92e;
  -webkit-box-shadow:inset 0px 1px 30px #5ac92e;

}
    75%   {

        border:2px solid #308BC4;
 
        box-shadow:inset  0px 1px 30px #308BC4;
        -moz-box-shadow:inset 0px 1px 30px #308BC4;
        -webkit-box-shadow:inset 0px 1px 30px #308BC4;

    }
100% {
    border:2px solid #4de7c6;
    box-shadow: inset  0px 1px 30px #4de7c6;
    -moz-box-shadow: inset 0px 1px 30px #4de7c6;
    -webkit-box-shadow: inset 0px 1px 30px #4de7c6;

}

}

/*-------------- 3 --------------*/

.to3{
  
  border:2px solid #00a329;
  background: rgba(0, 0, 0, 0.0);
  box-shadow: 0px 1px 30px #00a329;
  -moz-box-shadow:0px 1px 30px #00a329; /* FF*/
  -webkit-box-shadow:0px 1px 30px #00a329;
  box-shadow:0px 1px 30px #00a329; /*WC3*/

 
  
}
.oe3{
  border: 2px solid #FFF;
  width: 15;
 
  
}
.tit3{
  
 
  
  font-family: 'Montez', cursive;
  color: #FFF;
  background: rgba(0, 0, 0, 0.6);
  text-shadow:1px 1px 15px #00a329;
  border:2px solid #00a329;
 
  

}
.tit3{
   
   -webkit-animation: mymove3 5s infinite; 
    animation: mymove3 5s infinite;
}

/* Chrome, Safari, Opera */ 


/* Standard syntax */
@keyframes mymove3 {
  0%  {

    border:2px solid #00a329;
    box-shadow:  0px 1px 30px #00a329;
    -moz-box-shadow: 0px 1px 30px #00a329;
    -webkit-box-shadow: 0px 1px 30px #00a329;

}
25%  {

  border:2px solid #5ac92e;

  box-shadow:  0px 1px 30px #5ac92e;
  -moz-box-shadow: 0px 1px 30px #5ac92e;
  -webkit-box-shadow: 0px 1px 30px #5ac92e;

}
    75%   {

        border:2px solid #ffa600;
 
        box-shadow:  0px 1px 30px #ffa600;
        -moz-box-shadow: 0px 1px 30px #ffa600;
        -webkit-box-shadow: 0px 1px 30px #ffa600;

    }
100% {
    border:2px solid #4de7c6;
    box-shadow:  0px 1px 30px #4de7c6;
    -moz-box-shadow: 0px 1px 30px #4de7c6;
    -webkit-box-shadow: 0px 1px 30px #4de7c6;

}

}

.to3{
   
    
    
   -webkit-animation: mymoveDos3 5s infinite; 
    animation: mymoveDos3 5s infinite;
}

@keyframes mymoveDos3 {
  0%  {

    border:2px solid #00a329;
    box-shadow:inset  0px 1px 30px #00a329;
    -moz-box-shadow:inset 0px 1px 30px #00a329;
    -webkit-box-shadow:inset 0px 1px 30px #00a329;

}
25%  {

  border:2px solid #5ac92e;

  box-shadow:inset  0px 1px 30px #5ac92e;
  -moz-box-shadow:inset 0px 1px 30px #5ac92e;
  -webkit-box-shadow:inset 0px 1px 30px #5ac92e;

}
    75%   {

        border:2px solid #ffa600;
 
        box-shadow:inset  0px 1px 30px #ffa600;
        -moz-box-shadow:inset 0px 1px 30px #ffa600;
        -webkit-box-shadow:inset 0px 1px 30px #ffa600;

    }
100% {
    border:2px solid #4de7c6;
    box-shadow: inset  0px 1px 30px #4de7c6;
    -moz-box-shadow: inset 0px 1px 30px #4de7c6;
    -webkit-box-shadow: inset 0px 1px 30px #4de7c6;

}

}



/*-------------- 4 --------------*/

.to4{
  
  border:2px solid #db0482;
  background: rgba(0, 0, 0, 0.0);
  box-shadow: 0px 1px 30px #db0482;
  -moz-box-shadow:0px 1px 30px #db0482; /* FF*/
  -webkit-box-shadow:0px 1px 30px #db0482;
  box-shadow:0px 1px 30px #db0482; /*WC3*/

 
  
}
.oe4{
  border: 2px solid #FFF;
  width: 15;
 
  
}
.tit4{
  
 
  
  font-family: 'Montez', cursive;
  color: #FFF;
  background: rgba(0, 0, 0, 0.6);
  text-shadow:1px 1px 15px #db0482;
  border:2px solid #db0482;
 
  

}
.tit4{
   
   -webkit-animation: mymove4 5s infinite; 
    animation: mymove4 5s infinite;
}


/* Chrome, Safari, Opera */ 


/* Standard syntax */
@keyframes mymove4 {
  0%  {

    border:2px solid #db0482;
    box-shadow:  0px 1px 30px #db0482;
    -moz-box-shadow: 0px 1px 30px #db0482;
    -webkit-box-shadow: 0px 1px 30px #db0482;

}
25%  {

  border:2px solid #2ec950;

  box-shadow:  0px 1px 30px #2ec950;
  -moz-box-shadow: 0px 1px 30px #2ec950;
  -webkit-box-shadow: 0px 1px 30px #2ec950;

}
    75%   {

        border:2px solid #ffa600;
 
        box-shadow:  0px 1px 30px #ffa600;
        -moz-box-shadow: 0px 1px 30px #ffa600;
        -webkit-box-shadow: 0px 1px 30px #ffa600;

    }
100% {
    border:2px solid #fffc68;
    box-shadow:  0px 1px 30px #fffc68;
    -moz-box-shadow: 0px 1px 30px #fffc68;
    -webkit-box-shadow: 0px 1px 30px #fffc68;

}

}

.to4{
   
    
    
   -webkit-animation: mymoveDos4 5s infinite; 
    animation: mymoveDos4 5s infinite;
}

@keyframes mymoveDos4 {
  0%  {

    border:2px solid #db0482;
    box-shadow:inset  0px 1px 30px #db0482;
    -moz-box-shadow:inset 0px 1px 30px #db0482;
    -webkit-box-shadow:inset 0px 1px 30px #db0482;

}
25%  {

  border:2px solid #2ec950;

  box-shadow:inset  0px 1px 30px #2ec950;
  -moz-box-shadow:inset 0px 1px 30px #2ec950;
  -webkit-box-shadow:inset 0px 1px 30px #2ec950;

}
    75%   {

        border:2px solid #ffa600;
 
        box-shadow:inset  0px 1px 30px #ffa600;
        -moz-box-shadow:inset 0px 1px 30px #ffa600;
        -webkit-box-shadow:inset 0px 1px 30px #ffa600;

    }
100% {
    border:2px solid #fffc68;
    box-shadow: inset  0px 1px 30px #fffc68;
    -moz-box-shadow: inset 0px 1px 30px #fffc68;
    -webkit-box-shadow: inset 0px 1px 30px #fffc68;

}

}

.fondo-letras{
  padding: 0;
}
.page-features{
  margin-bottom: 50px;
}
.page-features h5, .page-features small {

  width: auto;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 10px;

}
.page-features small {
 
  border:2px solid #2ec950;
  box-shadow:inset  0px 1px 30px #2ec950;
  -moz-box-shadow:inset 0px 1px 30px #2ec950;
  -webkit-box-shadow:inset 0px 1px 30px #2ec950;
}

.page-features h5{
  
  width: auto;
  background-color: rgba(0, 0, 0, 0.5);
  border:2px solid #ffa600;
  box-shadow:inset  0px 1px 30px #ffa600;
  -moz-box-shadow:inset 0px 1px 30px #ffa600;
  -webkit-box-shadow:inset 0px 1px 30px #ffa600;
  

}


.feature-item{
  margin-top: 5px
}
.tit-centro {
  padding-top: 50px;
}
.page-footer{
  margin-top: 80px;
}

@media (max-width: 1024px){
  .page-footer{
    margin-top: 20px;
  }
  
  .page-features small {
    font-size: 12px;
  }
  .page-features h5{
    font-size: 15px;
  } 
  .page-features{
    margin-bottom: 25px;
  }
  
  .circ-neon{
   
    width: 100px;
    height: 100px;
    font-size: 10px;
  
  }
  .tit-centro {
    padding-top: 30px;
  }

}
@media (max-width: 400px){
  .page-footer{
    margin-top: 10px;
  }
  .page-features h5, .page-features small {
    padding: 5px;
  }
  .page-features small {
    font-size: 11px;
  }
  .page-features h5{
    font-size: 13px;
  } 
  .page-features{
    margin-bottom: 5px;
  }
  
  .circ-neon{
    width: 50px;
    height: 50px;
    font-size: 10px;
  }
  .tit-centro {
    padding-top: 10px;
  }

}
@media (max-width: 350px){
  .page-footer{
    margin-top: 5px;
  }
  .page-features h5, .page-features small {
    padding: 5px;
  }
  .page-features small {
    font-size: 11px;
  }
  .page-features h5{
    font-size: 13px;
  } 
  .page-features{
    margin-bottom: 5px;
  }
  
  .circ-neon{
    width: 50px;
    height: 50px;
    font-size: 10px;
  }
  .tit-centro {
    padding-top: 10px;
  }

  .dummy__item {
    margin: 0.01em 0.05em;
  }

}

.p-cv-center{
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.06);
  background-clip: border-box;
  border-radius: .25rem;
  margin-left: 10px;
  width: 50%;
  margin: 10px auto;
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
}


