.background-item-y  *{
    color: #fff ;
    -webkit-transition: all 400ms ease-in-out;
	-moz-transition: all 400ms ease-in-out;
	-o-transition: all 400ms ease-in-out;
	-ms-transition: all 400ms ease-in-out;
	transition: all 400ms ease-in-out;  
}
.background-item-y{
    position: relative;
    margin: auto;
    
}
.background-item-y, .img-scroll-y, .item-scroll-y, .button-background-y{
    width: 250px;
    height: 160px;
    
    border-radius: 5px;
   
}

.item-scroll-y > *{
    -webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
    display: block ;
    color: #000;
    -webkit-transform: translate3d(0, 100px, 0);
	transform: translate3d(0, 100px, 0);
    color: #FFFFFF;
    margin-top: 8px
}

.item-scroll-y{  
    position: absolute;
    top: 0;
    right: 0;
    color: #1a1a1a;
    background-color: rgba(174, 218, 103, 0.0);
    z-index: 9999999;
}

.button-background-y{
    position: absolute;
    background-color: rgba(255, 244, 144, 0.007);
    top: 0;
    right: 0;
    z-index: 999999;
    border: solid 2px rgba(255, 255, 255, 0.8);
}

.img-scroll-y{  
    position: absolute;
    overflow: hidden;
    z-index: 9999;
}
.img-b-y{
    
    width: 100%;
    -webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.8;
}
.background-item-y:hover .button-background-y {
    -webkit-animation-name: zoomOut;
    animation-name: zoomOut;
}
.background-item-y:hover .item-scroll-y {
    background-color: rgba(80, 112, 34, 0.95);
    -webkit-animation-name: flipInY;
    animation-name: flipInY;
}
.background-item-y:hover .img-b-y{
    -webkit-transform: scale(1.5);
	-moz-transform: scale(1.5);
	-o-transform: scale(1.5);
	-ms-transform: scale(1.5);
	transform: scale(1.5);
}
.item-scroll-y h3{  
    -webkit-transition-delay: 0.02s;
	transition-delay: 0.02s;  
}
.item-scroll-y strong{  
    -webkit-transition-delay: 0.08s;
	transition-delay: 0.08s; 
}
.item-scroll-y div{ 
    -webkit-transition-delay: 0.20s;
	transition-delay: 0.20s;  
}

.background-item-y:hover .item-scroll-y h3, 
.background-item-y:hover .item-scroll-y strong, 
.background-item-y:hover .item-scroll-y div{
    opacity: 1;
	transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);  
}
.btn-op-5-y{
    
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: rgba(57, 167, 230, 0.5);
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    
}
.img-over-y p{
    color: #1a1a1a;
}
.img-over-y{

    position: absolute;
    height: 0px; 
    width: 0px;
    background-color: rgb(255, 255, 255);
    opacity: 0;
    color: #1a1a1a;
    overflow: hidden;
    overflow-x: hidden;
    /*
        -webkit-transition: all 400ms ease-in-out;
	-moz-transition: all 400ms cubic-bezier(0.1, 0.7, 1.0, 0.1);
	-o-transition: all 400ms cubic-bezier(0.1, 0.7, 1.0, 0.1);
	-ms-transition: all 400ms cubic-bezier(0.1, 0.7, 1.0, 0.1);
	transition: all 400ms cubic-bezier(0.1, 0.7, 1.0, 0.1);
    */ 
}
.img-over-y::-webkit-scrollbar {
    display: none;
}

.img-big-y{
    width: 100%;
    z-index: 999999999999999;
    position: fixed;
    /* -webkit-animation-name: zoomIn;
    animation-name: zoomIn; */
    opacity: 1;
    border-radius: 0%;
    transform: scale(0);
     animation: redondeos 0.5s ease-in-out normal forwards; 
}
@keyframes redondeos{
    0% {
        transform: scale(1);
    }
    25%{
        transform: scale(1.1);
    }
    50%{
        transform: scale(1.2);
    }
    75%{
        transform: scale(1.1);
    }
    100%{
        transform: scale(1);
    }
} 
.img-big-dos-y{
    height: 0%; 
    width: 0%;
    /* -webkit-animation-name: zoomOut;
    animation-name: zoomOut; */
    position: fixed;
    opacity: 0;
}
/* @keyframes altos{
    0% {
        height: 80%; 
        width: 80%;
    }
    25%{
       height: 50%; 
        width: 50%;
    }
    50%{
       height: 40%; 
        width: 40%;
    }
    75%{
       height: 20%; 
        width: 20%;
    }
    100%{
       height: 0%; 
        width: 0%;
    }
} */

/* div, p, strong, h3 {
    border: solid 1px red;
}
 */

.closed-y{
    position: fixed;
    z-index: 9999999999;
    top: 0;
    left: 0;
    background-color: rgba(79, 128, 0, 0.9) !important;
}
.iframe-y:-webkit-scrollbar {
    width: 1px;     /* Tamaño del scroll en vertical */
    height: 1px;    /* Tamaño del scroll en horizontal */
    display: none;  /* Ocultar scroll */
    scrollbar-color: rgba(0, 0, 0, .5) rgba(0, 0, 0, 0);
    scrollbar-width: thin;
    color: #ffffff;
}

.iframe-y, .iframe-y-cont {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
    height: 100%;
}

/* Ponemos un color de fondo y redondeamos las esquinas del thumb */
.iframe-y-cont::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.iframe-y-cont::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
.iframe-y-cont::-webkit-scrollbar-thumb:active {
    background-color: #999999;
}
