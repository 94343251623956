.ipad-landscape {
    background-image: url(https://david-perez-portafolio.neonbo.com/img/ipad-landscape.png);
    padding: 55px 0 103px 85px;
    margin: auto;  
     
}
.deviceWrap {
   
    background-position: top center;
    background-size: 100%;
    background-repeat: no-repeat;
    margin: 60px auto 20px auto;
    position: relative;
    width: 1200px;
    box-shadow: 5px 5px 5pxp #1a1a1a;  
}


#devices .device {
    margin: 0 auto;
    position: relative;
    width: 1024px;
    height: 740px;
    border: solid 2px #1a1a1a;
}