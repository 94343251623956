
/* .press::before{
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 0;
    transition: all 0.6s ease;
    height: 0%;
    width: 0%;
    background-color: #ffffff;
} */
.press{
   

    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    
    padding: .375rem 2.75rem;
    font-size: 1rem;
    border-radius: .25rem;
    opacity: 1;
    background-color: #26BDF8;
}

.ripple{
   
    border-radius: 60%;
    background-color: rgba(255, 255, 255, 1);
    position: absolute;
    transform: scale(0);
    animation-fill-mode: backwards;
    animation: ripple 0.9s ease-in-out normal;
    z-index: 0;
  }
  
  @keyframes ripple{
    to{
      transform: scale(5.5);
      z-index: -5;
    }
  }

/* .press::before{
    width: 0%;
    height: 0%;
    color: #26BDF8;
    background-color: rgb(233, 233, 233);
}
.press-active{
    opacity: 0;
    color: #26BDF8;
}
.press-active::before{
    height: 100%;
    width: 100%;
}
.press-active-no{
    opacity: 1;
}
.press-active-no::before{
    width: 0%;
    height: 0%;
} */
.close{
}
.img-over {
    overflow:auto;
    box-sizing: border-box;
}

/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/

.img-over::-webkit-scrollbar {
    -webkit-appearance: none;
}

.img-over::-webkit-scrollbar:vertical {
    width:10px;
}

.img-over::-webkit-scrollbar-button:increment,.img-over::-webkit-scrollbar-button {
    display: none;
} 

.img-over::-webkit-scrollbar:horizontal {
    height: 10px;
}


/*
el scroll los que no son seleccionados tendran un efectp focal gausian y al aparecer se aprecian en su forma real
*/